import styled from 'styled-components'

const StyledFormWrapper = styled.div`
  margin-top: 3rem;
  
  form, .form-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2.5rem;
  }

  .c-span-2 {
    grid-column: auto / span 2;
  }

  h3,
  .c-span-full {
    grid-column: 1 / -1;
  }

  /* h3,
  .column-span-two {
    grid-column: 1 / span 2;
  } */

  span {
    label {
      display: block;
      color: #555;
      margin-bottom: 0.6rem;
    }

    input:not([type='checkbox']):not([type='radio']),
    textarea,
    select {
      width: 100%;
      padding: 0.4rem;
      font-size: 1.2rem;
      font-family: inherit;
      border: solid 1px #ccc;
    }

    input[type='checkbox'] {
      margin-right: 1rem;
      width: 1.6rem;
      height: 1.6rem;
    }

    input[type='radio'] {
      margin-right: 1rem;
      width: auto;
      height: auto;
    }

    /* option[value=''] {
      color: #555;
    } */

    p.error-message {
      color: red;
      margin-top: 0.2rem;
    }

    &.checkbox-input, &.radio-input {
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      label {
        display: initial;
        margin-bottom: 0;
      }

      input {
        flex: 0 0 auto;
      }

      p.error-message {
        flex-basis: 100%;
      }
    }

    &.error {
      input, select {
        border-color: red;
      }
    }
  }

  input[type='submit'], button[type='submit'], button.form-btn {
    display: inline-block;
    background-color: var(--primary-color);
    color: #f4f4f4;
    width: fit-content;
    margin: auto;
    padding: 0.6em 2em;
    font-size: 1.6rem;
    text-transform: uppercase;
    border: none;
    cursor: pointer;

    &:disabled {
      background-color: #ccc;
      cursor: wait;
    }
  }

  div.buttons {
    grid-column: 1 / -1;

    button {
      margin-right: 1rem;
    }
  }

  span.error {
    input {
      border-color: red;
    }

    div.error {
      margin-top: 0.4rem;
      color: red;
    }
  }

  fieldset {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
    margin-bottom: 1rem;
    padding: 2rem;
    border: solid 1px #555;
    border-color: lightgray;
    border-radius: 5px;

    legend {
      font-size: 1.2rem;
      font-weight: bold;
      text-decoration: underline 4px var(--primary-color);
    }
  }

  @media (max-width: 768px) {
    form, .form-container, fieldset {
      grid-template-columns: repeat(2, 1fr);
    }

    .c-span-2 {
      grid-column: 1 / -1;
    }
  }

  @media (max-width: 480px) {
    form, .form-container, fieldset {
      grid-template-columns: 1fr;
    }
  }
`

export default StyledFormWrapper