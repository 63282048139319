import React from 'react'
import { useField } from 'formik'

const StringInput = ({ label, style, className, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <span style={style} className={`${className || ''} ${meta.touched && meta.error ? 'error': ''}`}>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input {...field} {...props} />
      {meta.touched && meta.error ? (
        <p className='error-message'>{meta.error}</p>
      ): null}
    </span>
  )
}

const SelectInput = ({ label, className, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <span className={`${className || ''} ${meta.touched && meta.error ? 'error': ''}`}>
      <label htmlFor={props.id || props.name}>{label}</label>
      <select {...field} {...props} />
      {meta.touched && meta.error ? (
        <p className='error-message'>{meta.error}</p>
      ): null}
    </span>
  )
}

const CheckboxInput = ({ label, labelComponent, className, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'checkbox'})
  return (
    <span className={`${className || ''} ${meta.touched && meta.error ? 'error': ''} checkbox-input`}>
      <input {...field} {...props} type="checkbox" />
      <label htmlFor={props.id || props.name}>{label || labelComponent}</label>
      {meta.touched && meta.error ? (
        <p className='error-message'>{meta.error}</p>
      ): null}
    </span>
  )
}

export { StringInput, SelectInput, CheckboxInput }