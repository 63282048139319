import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import Seo from '../components/Seo'
import StyledFormWrapper from '../components/styles/StyledFormWrapper'
import StudentRegistrationForm from '../components/registration-forms/StudentRegistrationForm'
import CourseSelectionForm from '../components/registration-forms/CourseSelectionForm'
import PaymentOptionsForm from '../components/registration-forms/PaymentOptionsForm'
import encode from '../helpers/netlifyFormEncode'

// == TODO: == 
// create visual feedback on submit loading state. Formik has n 'loading' boolean to use

/*
  Links for when you want to create the Formik file input:
  - https://github.com/jaredpalmer/formik/issues/926
  - https://codesandbox.io/s/formik-file-input-with-validation-pn3vb
  - https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#value
  - https://www.npmjs.com/package/yup
  - https://medium.com/hackernoon/formik-handling-files-and-recaptcha-209cbeae10bc
  - https://formik.org/docs/guides/validation
*/

const phoneRegExp = /(^[+27][ 0-9]{14})|(^[+27]( ?)[0-9]{11})|(^0[0-9]{9})|(^0[ 0-9]{11})$/

const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
  
    reader.onload = () => {
      resolve(reader.result.replace("data:", "").replace(/^.+,/, ""))
    }

    reader.onerror = () => {
      reject('Could not convert file to base64.')
    }
    
    reader.readAsDataURL(file)
  })
}

const RegistrationPage = ({ data }) => {
  const learningMethods = data.allSanityLearningMethod.nodes
  const courses = data.allSanityCourse.nodes
  const [currentFormStep, setCurrentFormStep] = useState(0)
  const [chosenCourse, setChosenCourse] = useState(null)
  const [documents, setDocuments] = useState({})

  const fileInputChangeHandler = (event) => {
    setDocuments(prevDocuments => ({
      ...prevDocuments,
      [event.target.name]: {
        file: event.target.files[0]
      }
    }))
  }

  const handleBackBtnClick = (event) => {
    event.preventDefault()
    setCurrentFormStep(currStep => currStep - 1)
  }

  const handleNextBtnClick = () => {
    setCurrentFormStep(currStep => currStep + 1)
  }

  const formStepGroups = [
    <StudentRegistrationForm
      fileInputChangeHandler={fileInputChangeHandler}
    />, // step 1
    <CourseSelectionForm 
      learningMethods={learningMethods} 
      courses={courses}
      chosenCourse={chosenCourse}
      setChosenCourse={setChosenCourse}
      fileInputChangeHandler={fileInputChangeHandler}
    />, // step 2
    <PaymentOptionsForm 
      chosenCourse={chosenCourse}
    />, // step 3
  ]

  const validationSchemaArray = [
    // step 1 validation
    Yup.object().shape({
      title: Yup.string().required('Required'),
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      preferredFirstName: Yup.string().required('Required'),
      idNumber: Yup.string().required('Required'),
      nationality: Yup.string().required('Required'),
      gender: Yup.string().required('Required'),
      ethnicity: Yup.string().required('Required'),
      maritalStatus: Yup.string().required('Required'),
      homeLanguage: Yup.string().required('Required'),
      cellphoneNumber: Yup.string().matches(phoneRegExp, 'Invalid phone number').required('Required'),
      homeNumber: Yup.string().matches(phoneRegExp, 'Invalid phone number'),
      workNumber: Yup.string().matches(phoneRegExp, 'Invalid phone number'),
      email: Yup.string().email('Invalid email').required('Required'),
      sponsorFirstName: Yup.string(),
      sponsorSurname: Yup.string(),
      sponsorCellphoneNumber: Yup.string().matches(phoneRegExp, 'Invalid phone number'),
      sponsorEmail: Yup.string().email('Invalid email'),
      physicalAddress1: Yup.string().required('Required'),
      physicalAddress2: Yup.string(),
      suburb: Yup.string().required('Required'),
      postalCode: Yup.string().required('Required'),
      postalAddress1: Yup.string().required('Required'),
      postalAddress2: Yup.string(),
      postalSuburb: Yup.string().required('Required'),
      postalPostalCode: Yup.string().required('Required'),
    }),
    // step 2 validation
    Yup.object().shape({
      learningMethod: Yup.string().required('Required'),
      course: Yup.string().required('Required'),
      termsAndConditions: Yup.boolean().oneOf([true], 'Must accept terms and conditions')
    }),
    Yup.object().shape({
      chosenPaymentAmount: Yup.string().required('Required')
    })
  ]

  return (
    <StyledRegistrationPage>
      <Seo title='Registration'>
        <script src="https://www.payfast.co.za/onsite/engine.js"></script>
      </Seo>
      <h1>Registration</h1>
      <p className="breadcrumbs">
        <Link to='/'>Home</Link> {`>`} Registration
      </p>

      <StyledRegistrationForm>
        <Formik
          initialValues={{
            title: '',
            firstName: '',
            lastName: '',
            preferredFirstName: '',
            idNumber: '',
            nationality: '',
            gender: '',
            ethnicity: '',
            maritalStatus: '',
            homeLanguage: '',
            cellphoneNumber: '',
            homeNumber: '',
            workNumber: '',
            email: '',
            sponsorFirstName: '',
            sponsorLastName: '',
            sponsorCellphoneNumber: '',
            sponsorEmail: '',
            physicalAddress1: '',
            physicalAddress2: '',
            suburb: '',
            postalCode: '',
            postalAddress1: '',
            postalAddress2: '',
            postalSuburb: '',
            postalPostalCode: '',
            learningMethod: '',
            course: '',
            termsAndConditions: false,
            chosenPaymentAmount: '',
          }}
          validationSchema={validationSchemaArray[currentFormStep]}
          onSubmit={async (values) => {
            // ignore submit event unless on final step
            if (currentFormStep !== formStepGroups.length - 1) {
              handleNextBtnClick()
              return
            }

            // convert document state object into array
            const documentsArray = []

            for (let docKey in documents) {
              documentsArray.push({
                filename: docKey.replace('_', ' '),
                ...documents[docKey]
              })
            }
            
            const documentsData = Promise.all(documentsArray.map(async ({ filename, file }) => ({
              filename: filename,
              filetype: file.type,
              base64: await convertToBase64(file),
            })))
            
            const campusOnlineDataObj = {
              title: values.title,
              first_name: values.firstName,
              last_name: values.lastName,
              preferred_first_name: values.preferredFirstName,
              id_passport: values.idNumber,
              nationality: values.nationality,
              gender: values.gender,
              ethnicity: values.ethnicity,
              marital_status: values.maritalStatus,
              language: values.homeLanguage,
              cellphone: values.cellphoneNumber,
              home_number: values.homeNumber,
              work_number: values.workNumber,
              email: values.email,
              sponsor_first_name: values.sponsorFirstName,
              sponsor_surname: values.sponsorLastName,
              sponsor_cellphone: values.sponsorCellphoneNumber,
              sponsor_email: values.sponsorEmail,
              physical_address_1: values.physicalAddress1,
              physical_address_2: values.physicalAddress2,
              suburb: values.suburb,
              postcode: values.postalCode,
              postal_address_1: values.postalAddress1,
              postal_address_2: values.postalAddress2,
              postal_suburb: values.postalSuburb,
              postal_postcode: values.postalPostalCode,
              learning_method: values.learningMethod.replace(' Courses', ''), // .replaceAll('-', ' ')
              course: values.course,
              registration_paid: values.chosenPaymentAmount,
              course_start_date: chosenCourse.startDate,
              documents: await documentsData
            }

            try {
              if (typeof window !== 'undefined' && window.fbq) {
                window.fbq('track', 'CompleteRegistration', {
                  content_name: 'Student Registration',
                  content_category: values.course || 'Not Specified',
                  learning_method: values.learningMethod?.replace(' Courses', '') || 'Not Specified',
                  currency: 'ZAR',
                  value: values.chosenPaymentAmount || 0,
                  // Do NOT include: names, email, phone, ID number, or any other PII
                });
              }

              // send form data to CampusOnline
              await fetch('https://unicollege.campusonline.co.za/registration/student-registration.php', {
                method: 'POST',
                mode: 'no-cors',
                headers: {
                  'Content-type': 'application/json'
                },
                body: JSON.stringify(campusOnlineDataObj),
              })

              // send form data for Netlify
              await fetch('/', {
                method: 'POST',
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({
                  "form-name": "registration", // event.target.getAttribute("name"),
                  ...values,
                  courseStartDate: chosenCourse.startDate
                })
              })

              // Payment Redirect
              window.open(`https://www.payfast.co.za/eng/process?cmd=_paynow&receiver=17334751&item_name=${values.course.replaceAll(' ', '+')}+|+${campusOnlineDataObj.learning_method}&item_description=This+is+the+description&amount=${values.chosenPaymentAmount}&email_address=${values.email}&return_url=https://unicollege.co.za/thank-you-registration&cancel_url=https://unicollege.co.za/cancel-registration`, '_self');
            } catch (error) {
              console.log(error)
              alert('Sorry! An error occured when trying to submit your registration. Please try again.')
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form
              name='registration'
              data-netlify='true'
              data-netlify-honeypot='bot-field' 
              data-fb-pixel-inhibit="true"
              data-meta-pixel-inhibit="true"
            >
              {/* Netlify hidden fields */}
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="registration" />
              {/* These hidden fields are for netlify to pick-up and then accept the keys in the POST request. */}
              <input type="hidden" name="course" />
              <input type="hidden" name="learningMethod" />
              <input type="hidden" name="courseStartDate" />

              {/* == Current form elements == */}
              {formStepGroups[currentFormStep]}

              {/* == Buttons == */}
              <div className="buttons">
                {currentFormStep > 0 && 
                  <button 
                    className='form-btn' 
                    onClick={handleBackBtnClick} 
                    disabled={isSubmitting}
                  >Back</button>
                }
                <button 
                  type="submit" 
                  disabled={isSubmitting}
                >
                  {isSubmitting 
                    ? 'Submitting...'
                    : currentFormStep === formStepGroups.length - 1
                    ? 'Submit'
                    : 'Next'
                  }
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </StyledRegistrationForm>
    </StyledRegistrationPage>
  )
}

export const query = graphql`
  query RegistrationPageQuery {
    allSanityLearningMethod(sort: {fields: rank}) {
      nodes {
        title
        slug {
          current
        }
      }
    }
    allSanityCourse(sort: {fields: title}) {
      nodes {
        id
        title
        slug { current }
        courseStartDates {
          learningMethod {
            title
            slug { current }
          }
          startDate
          pricing {
            paymentOptions {
              description
              price
            }
          }
        }
      }
    }
  }
`

const StyledRegistrationPage = styled.main`
  max-width: 1400px;
  margin: auto;
  padding: 5rem 4rem 4rem 4rem;

  h2 {
    margin-bottom: 1rem;
    font-size: 1.75rem;
  }

  p {
    line-height: 1.5;
  }

  p.under-title {
    color: var(--primary-color);
    margin-bottom: 1rem;
    font-size: 0.9rem;
    font-weight: bold;
  }
  
  fieldset.hidden span:not(.checkbox-input) {
    display: none;
  }

  @media (max-width: 480px) {
    padding: 3rem 1rem 2rem 1rem;
  }
`

const StyledRegistrationForm = styled(StyledFormWrapper)`
  & > form {
    display: initial;
  }

  section.form-step {
    div.step-header {
      margin-bottom: 2rem;
    }
  }

  div[role='group'] {
    grid-column: 1 / -1;
    
    & > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  span.checkbox-input, span.radio-input {
    grid-column: 1 / -1;

    /* input {
      width: auto;
    } */

    div.error {
      flex-basis: 100%;
      width: 100%;
    }
  }

  img.uploaded-image {
    width: 400px;
  }

  div.buttons {
    margin-top: 2rem;

    button {
      font-size: 1.2rem;
    }
  }

  .course-start-date {
    h3 {
      margin-bottom: 0.4rem;
      font-weight: 400;
    }

    p {
      color: var(--primary-color);
      font-size: 1.6rem;
      font-weight: bold;
    }
  }
`

export default RegistrationPage


// import getPayFastUUID from '../helpers/payfast/getPayFastUUID'
// === was in the submit function ===
// try {
//   const payfastUUID = await getPayFastUUID({
//     // Buyer details
//     name_first: values.firstName,
//     name_last: values.lastName,
//     email_address: values.email,
//     // Transaction details
//     // amount: values.chosenPaymentAmount.toFixed(2).toString(),
//     // item_name: values.course,
//     amount: '5.00',
//     item_name: 'Test purchase item'
//   })

//   window.payfast_do_onsite_payment(payfastUUID, (result) => {
//     if (result === true) {
//       // Payment Completed
//       // maybe only send data to campusOnline here
//       // redirect to /thank-you-registration
//       navigate('/thank-you-registration')
//     } else {
//       // Payment Window Closed
//       // stay on registration page and notify of failure
//       alert('PayFast payment failed')
//       // able to try again
//     }
//   })
// } catch (error) {
//   console.log(error)
// }