import React from 'react'
import { Field, useField } from 'formik'
// import { StringInput, SelectInput, CheckboxInput } from './inputs'

const PaymentOptionsForm = ({ chosenCourse }) => {
  const [chosenPaymentField, chosenPaymentMeta] = useField('chosenPaymentAmount')

  const paymentOptionRadioElements = chosenCourse.paymentOptions.map(({ description, price }) => (
    <span key={price} className='radio-input'>
      <Field type="radio" name="chosenPaymentAmount" value={price} id={price} checked={String(price) === chosenPaymentField.value} />
      <label htmlFor={price}>{description} - R{price}</label>
    </span>
  ))

  return (
    <section className='form-step'>
      <div className="step-header">
        <h2>Payment (Step 3)</h2>
        <p>
          To process your application, we require you to choose one of the options below. <br />
          The registration fee shown is non-refundable.
        </p>
      </div>
      <div className="step-inputs form-container">
        <fieldset>
          <legend>What Payment would you like to make now?</legend>
          <div role='group'>
            {/* <span>
              <input type="radio" name="chosenPaymentAmount" value="test" id="test" />
              <label htmlFor="test">Test</label>
            </span> */}
            {paymentOptionRadioElements}
          </div>
          {(chosenPaymentMeta.touched && chosenPaymentMeta.error) && <p className='error-message'>{chosenPaymentMeta.error}</p> }
        </fieldset>
      </div>
      <p>When you click NEXT you will be redirected to our payment gateway to complete your registration payment.</p>
    </section>
  )
}

export default PaymentOptionsForm